
import { defineComponent } from 'vue';
import Breadcrumb from '@/views/new-design/layout/Breadcrumb.vue';
import { Actions, Getters } from '@/store/enums/AgencyEnums';
import { mapActions, mapGetters } from 'vuex';
import useBreakpoints from 'vue-next-breakpoints';

import ImageUploadModal from '@/components/ImageUploadModal.vue';
import AgencyUpdateModal from '@/components/agency/AgencyUpdateModal.vue';
import Details from '@/views/new-design/pages/Agencies/partials/Details.vue';
import { Business } from '@/models/BusinessModel';
import toasts from '@/utils/toasts';

import { Picture as IconPicture } from '@element-plus/icons-vue';

export default defineComponent({
  components: {
    Breadcrumb,
    ImageUploadModal,
    AgencyUpdateModal,
    Details,
    IconPicture,
  },
  data: () => {
    return {
      agencyDetails: {} as unknown as Business,
      loading: false,
      loadingDetails: false,
      activeMenu: 'details',
      showUpdateModal: false,
    };
  },
  async mounted() {
    await this.getDetails();
  },
  computed: {
    ...mapGetters({
      agency: Getters.GET_AGENCY,
      errors: Getters.GET_ACTION_ERRORS,
    }),

    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },

    menuTitles() {
      return {
        details: 'Overview',
      };
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    breadcrumbs() {
      return [
        {
          to: '/partners',
          text: 'Partner List',
          current: false,
        },
        {
          to: `/partners/${this.$route.params.id}`,
          text: 'Partner Details',
          current: true,
        },
      ];
    },

    getStatus() {
      if (this.agency.status === 'active') {
        return {
          type: 'success',
          text: 'Active',
          value: true,
          color: 'text-success',
          negativeIndicator: 'bi bi-x-circle',
        };
      }

      return {
        type: 'warning',
        text: 'Inactive',
        value: false,
        color: 'text-secondary',
        negativeIndicator: 'fas fa-check-circle',
      };
    },

    address() {
      const addressLine =
        this.agency && this.agency?.address_line
          ? this.agency.address_line
          : '';
      const city =
        this.agency && this.agency?.city ? `, ${this.agency.city}` : '';
      const state =
        this.agency && this.agency?.state ? `, ${this.agency.state}` : '';
      const postCode =
        this.agency && this.agency?.post_code
          ? ` ${this.agency.post_code}`
          : '';
      const country =
        this.agency && this.agency?.country ? `, ${this.agency.country}` : '';

      return `${addressLine}${city}${state}${postCode}${country}`;
    },
  },
  methods: {
    ...mapActions({
      fetchData: Actions.FETCH_AGENCY,
      deleteData: Actions.DELETE_AGENCY,
      uploadImage: Actions.UPLOAD_LOGO,
    }),

    async showTableData(menu) {
      this.activeMenu = menu;

      switch (menu) {
        default:
          this.getDetails();
          break;
      }
    },

    async getDetails() {
      if (this.agency.id) return;

      const params = {
        id: this.$route.params.id,
      };

      return await this.fetchData(params).catch(() => {
        console.log('ERROR');
      });
    },

    handleRemove() {
      return true;
    },

    handleEdit() {
      this.showUpdateModal = true;
    },

    confirmDelete() {
      return toasts
        .message('Are you sure to remove this partner?')
        .then(async ({ isConfirmed, value }) => {
          if (isConfirmed) {
            this.handleDelete(value);
          }
        });
    },

    async handleDelete(remarks) {
      const id = this.agency.id;
      const payload = {
        id: id,
        delete_remarks: remarks,
      };

      return await this.deleteData(payload)
        .then(async (data) => {
          this.agencyDetails = await data.data;
        })
        .catch((error) => {
          console.log('handleDeleteBusiness', error);
        });
    },

    handleUploadImage(values) {
      const payload = {
        id: this.agency.id,
        image: values,
      };

      this.uploadImage(payload)
        .then(() => {
          toasts.success('Image successfully uploaded');
        })
        .catch(() => {
          const { errors, message } = this.errors;
          toasts.error(errors, message);
        });
    },

    handleFormSubimitted() {
      this.showUpdateModal = false;
    },

    handleFormCancelled() {
      this.showUpdateModal = false;
    },
  },
  watch: {
    async agency(values) {
      const agencyData = await values;
      this.agencyDetails = agencyData;
    },
  },
});
